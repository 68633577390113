.nbs-flexisel-container {
	position:relative;
	max-width:100%;
	/*overflow: hidden;*/
	display: inline-block;
}

	.nbs-flexisel-inner {
		overflow:hidden;
		float:left;
		width:100%;
		max-height: 100%;
	}

		.nbs-flexisel-ul {
			position:relative;
			width:99999px;
			margin:0px;
			padding:0px;
			list-style-type:none;
			text-align:center;
		}
		
		.nbs-flexisel-item{
			float: left;
		}
		
	/*** Navigation ***/
	.nbs-flexisel-nav-left,
	.nbs-flexisel-nav-right {
		width: 26px;
		position: absolute;
		cursor: pointer;
		z-index: 100;
		
		top:8px !important;
		bottom: 8px;
		
		/*background-color: #FFF;*/
		background-color: rgba(255,255,255,0.5);
		border: solid 1px #CCC;		
		
		-webkit-transition: background-color 300ms ease-out;
		-moz-transition: background-color 300ms ease-out;
		-o-transition: background-color 300ms ease-out;
		transition: background-color 300ms ease-out;
	}
	.nbs-flexisel-nav-left:hover,
	.nbs-flexisel-nav-right:hover {
		background-color: #FFF;
		background-color: rgba(255,255,255,1);
	}
	
	.nbs-flexisel-nav-left {
		left: 0;
		background-position: center left;
	}
	.nbs-flexisel-nav-right {
		right: 0;
		background-position: center right;
	}